/*
 * @Author: wyq
 * @Date: 2021-07-08 16:03:58
 * @LastEditTime: 2021-07-19 14:27:15
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \question_bank_console\src\utils\questionComponentMixin.js
 */
import uploadImg from '@/components/edit/uploadImg'
export default {
  props: {
    questionForm: Object,
    isReset: {
      type: Boolean,
      default: false,
    },
    submitLoading: Boolean
  },
  watch: {
    questionForm: {
      handler(val) {
        this.form = { ...this.form, ...val }
      },
      immediate: true,
    },
  },
  methods: {
    // 后退
    clickGoback() {
      this.$router.go(-1)
    },
    // 预览
    clickPreview() {
      this.$emit('preview', this.form)
    },
    // 数据格式化
    formatFormToParams(data, type) {
      let result = {}
      // 基础信息格式化
      let constantLabel = []
      switch (type) {
        case 1:
          constantLabel = [
            'title',
            'optionNum',
            'answer',
            'answerProcess',
            'answerAnalysis',
            'answerAnalysis',
            'difficultyLevel',
            'ansSecond',
            'discrimination',
            'guessing'
          ]
          break;
        case 2:
          constantLabel = [
            'title',
            'answer',
            'answerProcess',
            'answerAnalysis',
            'difficultyLevel',
            'ansSecond',
            'discrimination',
            'guessing'
          ]
          break;
        case 3:
          constantLabel = [
            'title',
            'answerProcess',
            'answerAnalysis',
            'difficultyLevel',
            'ansSecond',
            'discrimination',
            'guessing'
          ]
          break;
        case 4:
          constantLabel = [
            'title',
            'optionNum',
            'answerProcess',
            'answerAnalysis',
            'answerAnalysis',
            'difficultyLevel',
            'ansSecond',
            'discrimination',
            'guessing'
          ]
          break;
      }
      for (const i of constantLabel) {
        result[i] = data[i]
      }
      // 图片格式化
      const imgLabel = ['title', 'answerProcess', 'answerAnalysis']
      for (const i of imgLabel) {
        // console.log(`${i}Images`, data[`${i}Images`])
        result[`${i}Photo`] = data[`${i}Images`].map((i) => {
          return {
            name: i.label,
            url: i.response ? i.response.Data.url : i.url,
          }
        })
      }
      // 单选题选项格式化
      if (type == 1 || type == 4) {
        const { options } = data
        for (let i = 0; i < options.length; i++) {
          result[`option${i + 1}`] = options[i].value
          // console.log(i, options[i].imageUrl)
          result[`option${i + 1}Photo`] = options[i].imageUrl.map((i) => {
            return {
              name: i.label,
              url: i.response ? i.response.Data.url : i.url,
            }
          })
        }
      }
      if (type == 4) {
        result.answer = data.arrAnswer.join(',')
      }
      result.titleHjyPhoto = data.titleHjyPhoto?.map(i => {
        return {
          name: i.label,
          url: i.response ? i.response.Data.url : i.url,
        }
      })
      return result
    }
  },
  components: {
    uploadImg,
  },
}