<!--
 * @Author: wyq
 * @Date: 2021-06-18 11:06:47
 * @LastEditTime: 2021-10-21 16:07:23
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\edit\fillsUpQuestion.vue
-->
<template>
  <div class="fills-up-question question-form">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="110px"
      label-position="left"
      size="mini"
    >
      <el-form-item label="题干" prop="title">
        <el-input v-model="form.title" type="textarea" :rows="4" resize="none"></el-input>
      </el-form-item>
      <el-form-item class="block-upload">
        <!--图片需为PNG格式，大小不超过100KB-->
        <upload-img :file-list.sync="form.titleImages"></upload-img>
      </el-form-item>
			<el-form-item class="block-upload" label="海经院图片">
				<!--图片需为PNG格式，大小不超过100KB-->
				<upload-img :file-list.sync="form.titleHjyPhoto"></upload-img>
			</el-form-item>
      <el-form-item label="正确答案" prop="answer">
        <el-row>
          <el-col :span="22">
            <el-input v-model="form.answer" disabled></el-input>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="keyboardShow">输入答案</el-button>
            <math-keyboard :show.sync="show" :value.sync="form.answer"></math-keyboard>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="解答过程" prop="answerProcess">
        <el-input v-model="form.answerProcess" type="textarea" :rows="4" resize="none"></el-input>
      </el-form-item>
      <el-form-item class="block-upload">
        <!--图片需为PNG格式，大小不超过100KB-->
        <upload-img :file-list.sync="form.answerProcessImages"></upload-img>
      </el-form-item>
      <el-form-item label="答案分析">
        <el-input v-model="form.answerAnalysis" type="textarea" :rows="4" resize="none"></el-input>
      </el-form-item>
      <el-form-item class="block-upload">
        <!--图片需为PNG格式，大小不超过100KB-->
        <upload-img :file-list.sync="form.answerAnalysisImages"></upload-img>
      </el-form-item>
      <el-form-item label="难度" prop="difficultyLevel">
        <el-input-number
          v-model="form.difficultyLevel"
          controls-position="right"
          :min="0.1"
          :max="0.9"
          :step="0.1"
        ></el-input-number>
        <span>(0～1)</span>
      </el-form-item>
      <el-form-item label="推荐答题时间" prop="ansSecond">
        <el-input-number v-model="form.ansSecond" controls-position="right" :min="60" :max="7200"></el-input-number>
        <span>秒</span>
      </el-form-item>
      <el-form-item label="区分度" prop="ansSecond">
        <el-input-number
          v-model="form.discrimination"
          controls-position="right"
          :min="-1"
          :max="1"
          :step="0.01"
        ></el-input-number>
        <span>[-1,1]</span>
      </el-form-item>
      <el-form-item label="猜测因子" prop="ansSecond">
        <el-input-number
          v-model="form.guessing"
          controls-position="right"
          :min="0"
          :max="1"
          :step="0.01"
        ></el-input-number>
        <span>[0,1]</span>
      </el-form-item>
      <slot></slot>
      <el-form-item class="footer-btn">
        <el-button v-if="isReset" size="medium" @click="clickReset">重置</el-button>
        <el-button v-else size="medium" @click="clickGoback">后退</el-button>
        <el-button type="warning" size="medium" @click="clickPreview">预览</el-button>
        <el-button type="primary" size="medium" :loading="submitLoading" @click="clickSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import mixin from '@/utils/questionComponentMixin.js'
export default {
  mixins: [mixin],
  data() {
    return {
      form: {
        title: '',
        titleImages: [],
				titleHjyPhoto:[],
        answer: '',
        answerProcess: '',
        answerProcessImages: [],
        answerAnalysis: '',
        answerAnalysisImages: [],
        difficultyLevel: 0.5,
        ansSecond: 120,
        discrimination: 0.78,
        guessing: 0.25,
      },
      rules: {
        title: [{ required: true, message: '请输入题干', trigger: 'blur' }],
        answer: [
          { required: true, message: '请输入正确答案', trigger: 'blur' },
        ],
        answerProcess: [
          { required: true, message: '请输入解答过程', trigger: 'blur' },
        ],
        difficultyLevel: [
          { required: true, message: '请输入难度', trigger: 'blur' },
        ],
        ansSecond: [
          { required: true, message: '请输入推荐答题时间', trigger: 'blur' },
        ],
        discrimination: [
          { required: true, message: '请输入区分度', trigger: 'blur' },
        ],
        guessing: [
          { required: true, message: '请输入猜测因子', trigger: 'blur' },
        ],
      },
      show: false,
    }
  },
  methods: {
    // 重置
    clickReset() {
      this.form = {
        title: '',
        titleImages: [],
				titleHjyPhoto:[],
        answer: '',
        answerProcess: '',
        answerProcessImages: [],
        answerAnalysis: '',
        answerAnalysisImages: [],
        difficultyLevel: 0.5,
        ansSecond: 120,
        discrimination: 0.78,
        guessing: 0.25,
      }
    },
    clickSave() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 数据提交
          this.$emit('save', this.formatFormToParams(this.form, 2))
        }
      })
    },
    keyboardShow() {
      this.show = true
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../assets/style/questionForm.scss';
.keyboard-input {
}
</style>