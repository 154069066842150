<template>
	<div>
		<el-form-item label="视频标签筛选">
			<el-cascader
				v-model="videoLabelId"
				:options="videoLabelOptions"
				:props="{ value: 'id', emitPath: false }"
				clearable
				@change="searchVideo"
			></el-cascader>
		</el-form-item>
		<el-form-item label="视频名称">
			<el-select
				v-model="idList"
				multiple
				filterable
				placeholder="请选择"
				@change="videoIdChange"
			>
				<el-option
					v-for="item in videoOptions"
					:key="item.id"
					:label="item.title"
					:value="item.id"
				></el-option>
			</el-select>
		</el-form-item>
	</div>
</template>
<script>
export default {
	model: {
		prop: 'value', // 对应 props msg
		event: 'update:value',
	},
	props: {
		value: Array,
	},
	data() {
		return {
			videoLabelId: null,
			videoLabelOptions: [],
			idList: [],
			videoOptions: [],
		}
	},
	watch: {
		value: {
			handler(val) {
				if (val != this.idList) {
					this.idList = [...val]
				}
			},
			deep: true,
		},
	},
	mounted() {
		this.$service.videoGraphList().then((res) => {
			this.videoLabelOptions = res.graph || []
		})
		this.searchVideo()
	},
	methods: {
		searchVideo() {
			const params = {
				videoLabelId: this.videoLabelId,
				pageSize: 100,
				pageNum: 1,
				checkStatus: 2,
			}
			this.$service.searchVideo(params).then((res) => {
				this.videoOptions = res.videoList
			})
		},
		videoIdChange(val) {
			this.$emit('update:value', val)
		},
		initVideoForm(questionId) {
			this.$service.getQuestionVideoRelationList({ questionId }).then((res) => {
				this.idList = res.questionVideoRelationList?.map((i) => i.videoDetail.id) || []
			})
		},
		submitVideo(questionId) {
			const params = {
				questionId,
				idList: this.idList,
			}
			if (this.idList.length) this.$service.addQuestionVideoRelation(params)
		},
	},
}
</script>
<style lang="scss" scoped>
// @import '../../assets/style/questionForm.scss';
.el-cascader,
.el-select {
	width: 100%;
}
</style>
